import classNames from "classnames";
import React, {
    useEffect,
    useState
} from "react";
import {useTranslation} from "react-i18next";
import {NumericFormat} from "react-number-format";
import {
    connect,
    useDispatch,
    useSelector
} from "react-redux";
import {NavLink} from "react-router-dom";
import {useMediaQuery} from "../../../helper/useMediaQuery";
import {CartPreviewDTO} from "../../../models/cart/CartDTO";
import {updateCartPreview} from "../../../redux-store/actions/cartPreviewAction";
import {getWishlistCount} from "../../../redux-store/api/WishlistApi";
import {
    selectWishlistCount,
    update
} from "../../../redux-store/reducers/wishlistCountReducer";
import {CartPreview} from "../../CartPreview/CartPreview";
import {FullScreenModeState} from "../../../redux-store/reducers/FullScreenModeReducer";
import Tooltip from "rc-tooltip";

import {ReactComponent as CartSvg} from '../../../assets/icons/svg/cart.svg';
import {ReactComponent as FavoriteSvg} from '../../../assets/icons/svg/favorite.svg';
import {ReactComponent as UserSvg} from '../../../assets/icons/svg/user.svg';
import {ReactComponent as PhoneSvg} from '../../../assets/icons/svg/phone.svg';
import {ReactComponent as FastShippingSvg} from '../../../assets/icons/svg/fast-shipping.svg';

function Header(props: any) {
    const {t} = useTranslation();
    const cartPreview = useSelector<any, CartPreviewDTO>((state) => state.cartPreview);
    const wishlistCount = useSelector<any, number>(selectWishlistCount);
    const isSmallScreen = useMediaQuery("(max-width: 991.98px)");
    const [isNavCollapsed, setIsNavCollapsed] = useState<boolean>(true);
    const dispatch = useDispatch();
    const [fullscreen, setFullscreen] = useState(false);
    const fullscreenModeSelector = useSelector<any, FullScreenModeState>(state => state.fullScreenMode)

    useEffect(() => {
        props.updateCartPreview();
        getWishlistCount().then(count => dispatch(update(count)));
        setIsNavCollapsed(true);
    }, []);

    //solute tracker
    useEffect(() => {
        if(location.href.indexOf("soluteclid") < 0) {
            return;
        }

        localStorage.setItem("soluteclid", (new Date()).getTime() + " " + location.href);
        let url = "https://cmodul.solutenetwork.com/landing";
        url += "?url=" + encodeURIComponent(location.href);
        const req = new XMLHttpRequest();
        req.open("GET", url);
        req.send();
    }, []);


    useEffect(() => {
        setFullscreen(fullscreenModeSelector.isEnabled);
    }, [fullscreenModeSelector]);

    const toggleNavigation = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsNavCollapsed(!isNavCollapsed);
    };

    const labelPrefix = "NAVIGATION_BAR."
    const headerNavLinks: { label: string, link: string, icon?: any }[] = [
        {
            label: labelPrefix + "CONTACT_PHONE_NUMBER",
            link: "/kontakt",
            icon: PhoneSvg
        }, {
            label: labelPrefix + "DELIVERY_INFORMATION",
            link: "/versand",
            icon: FastShippingSvg
        },
    ]

    const navbarLinks: { label: string, link: string, icon?: string, showVisuallyHidden?: boolean }[] = [
        {
            label: labelPrefix + "CONFIGURATOR",
            link: "/",
            showVisuallyHidden: true
        }, {
            label: labelPrefix + "RIM_OVERVIEW",
            link: "/felgen",
            showVisuallyHidden: true
        }, {
            label: labelPrefix + "TYRES",
            link: "/reifen",
            showVisuallyHidden: false
        },
    ];

    if(fullscreen) {
        return <div className={`fullscreen-nav fullscreen-nav-collapsed-${!isNavCollapsed}`}
                    onClick={() => setIsNavCollapsed(true)}>
            <a href="/">
                <div className="logo-header"/>
            </a>
            <nav className="navbar navbar-expand-xl navbar-light default-color">
                <button
                    className="navbar-toggler"
                    type="button"
                    aria-controls="navbarSupportedContent-333"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={toggleNavigation}
                >
                    <span className="navbar-toggler-icon"/>
                </button>
                <div
                    className={classNames("navbar-collapse", {collapse: isNavCollapsed})}
                    id="navbarSupportedContent-333"
                >
                    <ul className="navbar-nav main-nav me-auto">
                        {navbarLinks.map(link => {
                            return <li key={link.label} className="nav-item">
                                <NavLink className="nav-link" to={link.link}>
                                    {t(link.label)}
                                    {link.showVisuallyHidden && <span className="visually-hidden">
                                                    (current)
                                        </span>}
                                </NavLink>
                            </li>
                        })}
                    </ul>
                </div>
            </nav>
        </div>
    } else {
        return (<div className="header">
            <div className="header-top">
                <div className="container-fluid over-nav">
                    <ul className="navbar-nav me-auto">
                        {headerNavLinks.map((headerLink) => {
                            const Icon = headerLink.icon;
                            return <li key={headerLink.label} className="nav-item">
                                <NavLink className="nav-link" title={t(headerLink.label)} to={headerLink.link}>
                                    <Icon height={'1rem'} className={'text-highlight'}/>
                                    &nbsp;
                                    {t(headerLink.label)}
                                </NavLink>
                            </li>
                        })}
                        {/*<li className="ms-2">*/}
                        {/*    <Tooltip placement="right" trigger={["click", "hover"]} overlay={<span>{t("HEADER.PAYPAL_INFO")}</span>}>*/}
                        {/*        <img className="py-2 img-fluid" src="/payment/paypal.svg" alt=""/>*/}
                        {/*    </Tooltip>*/}
                        {/*</li>*/}
                    </ul>
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item active">
                            <a className="nav-link" href="/">
                                {t("NAVIGATION_BAR.START_PAGE")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://b2b.wheelscompany.com">
                                {t("NAVIGATION_BAR.B2B")}
                            </a>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/faq">
                                {t("NAVIGATION_BAR.SERVICE_INFO")}
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="container-fluid over-nav-mobile">
                    <ul className="navbar-nav me-auto">
                        {headerNavLinks.map(headerLink => {
                            const Icon = headerLink.icon;
                            return <li key={headerLink.label} className="nav-item">
                                <NavLink className="nav-link" title={t(headerLink.label)} to={headerLink.link}>
                                    <Icon height={'1rem'}/>
                                </NavLink>
                            </li>
                        })}
                    </ul>
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/warenkorb" title={t('CART.LINK_TITLE')}>
                                <CartSvg height={'1rem'} className={'me-2'}/>

                                {cartPreview?.sumGross ? (<NumericFormat
                                    value={cartPreview?.sumGross}
                                    displayType="text"
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix="€"
                                />) : ("")}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/favoriten">
                                <FavoriteSvg height={'1rem'}/>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/einstellungen">
                                <UserSvg height={'1rem'}/>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="header-main-nav" onClick={() => setIsNavCollapsed(true)}>
                <a href="/">
                    <div className="logo-header"/>
                </a>
                <nav className="navbar navbar-expand-xl navbar-dark default-color">
                    <button
                        className="navbar-toggler"
                        type="button"
                        aria-controls="navbarSupportedContent-333"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={toggleNavigation}
                    >
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div
                        className={classNames("navbar-collapse", {collapse: isNavCollapsed})}
                        id="navbarSupportedContent-333"
                    >
                        <ul className="navbar-nav main-nav me-auto">
                            {navbarLinks.map(link => {
                                return <li key={link.label} className="nav-item">
                                    <NavLink className="nav-link" to={link.link}>
                                        {t(link.label)}
                                        {link.showVisuallyHidden && <span className="visually-hidden">
                                                (current)
                                            </span>}
                                    </NavLink>
                                </li>
                            })}
                        </ul>
                    </div>
                    {!isSmallScreen && <div className="d-flex nav-icons-right">
                        <ul className="navbar-nav ms-auto d-none d-lg-flex flex-lg-row">
                            <CartPreview cartPreview={cartPreview}/>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/favoriten">
                                <div className="position-relative">
                                    <FavoriteSvg height={'1.5rem'}/>
                                    {wishlistCount > 0 && (<span
                                        className="position-absolute translate-middle badge rounded-pill">
                                        {wishlistCount}
                                    </span>)}
                                </div>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/einstellungen">
                                <UserSvg height={'1.5rem'}/>
                                </NavLink>
                            </li>
                        </ul>
                    </div>}
                </nav>
            </div>
        </div>);
    }
}

const mapDispatchToProps = {
    updateCartPreview
};

export default connect(null, mapDispatchToProps)(Header);
