import React, {useContext, useEffect, useState} from "react";
import LoginCustomer from "./LoginCustomer/LoginCustomer";
import NewCustomer from "./NewCustomer/NewCustomer";
import {CustomerDataDTO} from "../../../models/cart/CustomerDataDTO";
import UserContext from "../../../context/UserContext";
import {UserContextType} from '../../../context/UserContextType';
import {getShoppingCart} from "../../../redux-store/api/ShoppingCartApi";
import {PaypalIntegration} from "../../../components/PayPal/PaypalIntegration";
import {CartDTO} from "../../../models/cart/CartDTO";
import {useTranslation} from "react-i18next";

interface Props {
    nextStep: Function;
    updateCart: Function;
    cart: CartDTO;
}

function CustomerData(props: Props) {
    const [t] = useTranslation();
    const {loggedIn, user} = useContext(UserContext) as UserContextType;
    const [customerData, setCustomerData] = useState<CustomerDataDTO>(undefined);

    useEffect(() => {
        getShoppingCart().then((cart) => {
            props.updateCart(cart);
        });
    }, []);

    useEffect(() => {
        if (loggedIn && user) {
            setCustomerData(user);
        }
    }, [loggedIn]);

    function updateCustomerData(customerData: CustomerDataDTO) {
        setCustomerData(customerData);
    }

    return (
        <>
            <div className="row">
                <div className={loggedIn ? 'col-12 col-lg-3' : 'd-none'}></div>
                <div className="col-12 col-lg-6">
                    <div className="panel">
                        <NewCustomer
                            nextStep={props.nextStep}
                            customerData={customerData}/>
                    </div>
                </div>
                <div className={loggedIn ? 'col-12 col-lg-3' : 'd-none'}></div>
                {!loggedIn &&
                    <div className="col-12 col-lg-6">
                        <div className="panel">
                            <LoginCustomer updateCustomerData={updateCustomerData}/>
                            {/*<div className="text-center font-italic my-3">{t('CART.ALTERNATIVE_PAY_PAL')}</div>*/}
                            {/*<div className="checkout-form">*/}
                                {/*<PaypalIntegration canSubmit={true} cart={props.cart}/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                }
            </div>
        </>
    );

}

export default CustomerData;
