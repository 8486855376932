import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { NumberParam, useQueryParam } from "use-query-params";
import { AnalyticsHelper } from "../../../../../analytics/AnalyticsHelper";
import AddedToCartModal from "../../../../../components/AddedToCartModal/AddedToCartModal";
import CustomModal from "../../../../../components/CustomModal/CustomModal";
import RatePayInfo from "../../../../../components/RatePayInfo/RatePayInfo";
import RecommendedRetailPrice from "../../../../../components/RecommendedRetailPrice/RecommendedRetailPrice";
import { StockInfo } from "../../../../../components/StockInfo/StockInfo";
import TyreEuLabel from "../../../../../components/TyreEuLabel/TyreEuLabel";
import TyrePrice from "../../../../../components/TyrePrice/TyrePrice";
import { StringHelper } from "../../../../../helper/StringHelper";
import useFullPageLoader from "../../../../../helper/useFullPageLoader";
import { useMediaQuery } from "../../../../../helper/useMediaQuery";
import { TpmsItem } from "../../../../../models/car/TpmsCheckDTO";
import { RimDetailDTO } from "../../../../../models/rims/RimDetailDTO";
import { RimSizeAssignmentDTO } from "../../../../../models/rims/RimSizeAssignmentDTO";
import { Axle } from "../../../../../models/shared/Enums";
import { MountedWheelCostsDTO } from "../../../../../models/tyres/MountedWheelDetailsDTO";
import { TyreDTO } from "../../../../../models/tyres/TyreDTO";
import { WishListDTO } from "../../../../../models/wishlist/WishlistDTO";
import { updateCartPreview } from "../../../../../redux-store/actions/cartPreviewAction";
import { checkForTpms } from "../../../../../redux-store/api/CarApi";
import {
    addCompleteWheelToShoppingCart,
    deletePositionFromCartById
} from "../../../../../redux-store/api/ShoppingCartApi";
import { getMountedWheelDetails } from "../../../../../redux-store/api/TyreApi";
import { addArticleToWishlist, deleteArticleFromWishlist } from "../../../../../redux-store/api/WishlistApi";
import { decrement, update } from "../../../../../redux-store/reducers/wishlistCountReducer";
import GridViewTyreCard from "./GridViewTyreCard";
import ListViewTyreCard from "./ListViewTyreCard";

const noImageUrl = "/no_images/reifen.svg";

interface TyreCardProps {
    tyre: TyreDTO;
    isCompleteWheelSearch: boolean;
    carId?: string;
    rimFrontId?: string;
    rimRearId?: string;
    updateCartPreview?: () => void;
    mountedWheelCosts?: MountedWheelCostsDTO;
    rimDetails?: RimDetailDTO;
    showListView?: boolean;
    getWishlistCallback: Function;
    wishlistEntries?: WishListDTO[];
    isSmallScreen?: boolean;
}

export interface SubTyreCardProps {
    tyre: TyreDTO;
    showTyreInfos: Function;
    showButton: Function;
    noImageUrl: string;
    changeFavorite: Function;
    isFavorite: boolean;
    smallScreen?: boolean;
    isCompleteWheelSearch?: boolean;
    detailsClicked?: Function;
    chooseTyreForMountedWheel?: Function;
    isMountedWheel?: boolean;
}


function TyreCard(props: TyreCardProps) {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const [selectedAmount, setSelectedAmount] = useQueryParam("selectedAmount", NumberParam);
    const [oldPositionId, setOldPositionId] = useQueryParam("positionId", NumberParam);
    const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);
    const isFavorite: boolean = !!props.wishlistEntries?.find((entry) => entry.productId == props.tyre.productId);

    const isVerySmallScreen = useMediaQuery("(max-width: 650px)");
    const { loader, showLoader, hideLoader } = useFullPageLoader();

    const chooseTyreForMountedWheel = () => {
        showLoader();
        checkForTpms(props.carId).then((res) => {
            hideLoader();
            if (res.tpmsRequired) {
                let selectedTpms = res.items.find(i => i.productId === -101 || i.productId === -111);
                if(!selectedTpms && res.items.length > 0) {
                    selectedTpms = res.items[0];
                } else if(!selectedTpms) {
                    selectedTpms= res.noTpmsItem;
                }
                orderCompleteWheel(true, selectedTpms);
            } else {
                orderCompleteWheel(false);
            }
        });
    };

    const changeFavorite = (evt: React.MouseEvent<HTMLElement>) => {
        evt.stopPropagation();
        let { getWishlistCallback, wishlistEntries } = props;

        const wishlistEntry = wishlistEntries?.find((entry) => entry.productId == props.tyre.productId);
        if (wishlistEntry) {
            deleteArticleFromWishlist(wishlistEntry.id)
                .then((res) => {
                    dispatch(decrement());
                    getWishlistCallback();
                })
                .catch((error) => {
                    console.error("deleteArticleFromWishlist", error);
                });
        } else {
            addArticleToWishlist({
                description: `${props.tyre.brandName} - ${props.tyre.description}`,
                imageLink: props.tyre.imageDTO.medium,
                itemType: "TYRE",
                productId: props.tyre.productId,
                wishlistId: 1
            })
                .then((res) => {
                    dispatch(update(res.wishlistEntries?.length));
                    getWishlistCallback(res.wishlistEntries);
                })
                .catch((error) => {
                    console.error("addArticleToWishlist", error);
                });
        }
    };

    const orderCompleteWheel = (tpmsAdded: boolean, tpmsItem?: TpmsItem) => {
        showLoader();
        getMountedWheelDetails(props.carId, props.rimFrontId, props.rimRearId, props.tyre.productId, props.tyre.rearTyre?.productId || props.tyre.productId)
            .then((mountedWheelDetails) => {
                const rim: RimDetailDTO = mountedWheelDetails.rimDetails;
                let rimSize: RimSizeAssignmentDTO;
                if (props.rimRearId == null || props.rimRearId === props.rimFrontId) {
                    rimSize = mountedWheelDetails.rimDetails.rimDetailsSizes.find((item) => item.front.rimIdCode === props.rimFrontId);
                } else {
                    rimSize = mountedWheelDetails.rimDetails.rimDetailsSizes
                        .find((item) => item.front.rimIdCode === props.rimFrontId && item.rear != null && item.rear.rimIdCode === props.rimRearId);
                }
                const frontTyre: TyreDTO = mountedWheelDetails.frontTyreDetails;
                let rearTyre: TyreDTO;
                if (props.tyre.rearTyre?.productId != null && props.tyre.productId != props.tyre.rearTyre?.productId) {
                    rearTyre = mountedWheelDetails.rearTyreDetails;
                }
                if (!rim.rimRear && (!rearTyre || rearTyre?.productId == frontTyre?.productId)) {
                    addCompleteWheelToShoppingCart([{
                        carId: props.carId,
                        rim: rim.rimFront,
                        rimSize: rimSize.front,
                        certificateId: rimSize.certificateId,
                        certificateBlockId: rimSize.certificateBlockId,
                        tyre: frontTyre,
                        amount: selectedAmount,
                        tpmsAdded: tpmsAdded,
                        tpmsItem: tpmsItem,
                        axle: Axle.BOTH,
                        kit: rim.mixedDataRimInformation.kitFront,
                        mountingCosts: mountedWheelDetails.mountingCostsFront
                    }])
                        .then((res) => {
                            if (oldPositionId) {
                                deleteOldPositionFromCart();
                            } else {
                                props.updateCartPreview();
                                hideLoader();
                                setShowAddedToCartModal(true);
                            }

                        })
                        .catch((error) => {
                            console.error(error);
                        });
                } else {
                    const frontReq = {
                        carId: props.carId,
                        rim: rim.rimFront,
                        rimSize: rimSize.front,
                        certificateId: rimSize.certificateId,
                        certificateBlockId: rimSize.certificateBlockId,
                        tyre: frontTyre,
                        amount: selectedAmount * 0.5,
                        tpmsAdded: tpmsAdded,
                        tpmsItem: tpmsItem,
                        axle: Axle.FRONT,
                        kit: rim.mixedDataRimInformation.kitFront,
                        mountingCosts: mountedWheelDetails.mountingCostsFront
                    };
                    let rearReq = {
                        carId: props.carId,
                        rim: rim.rimRear ? rim.rimRear : rim.rimFront,
                        rimSize: rimSize.rear ? rimSize.rear : rimSize.front,
                        certificateId: rimSize.certificateId,
                        certificateBlockId: rimSize.certificateBlockId,
                        tyre: rearTyre ? rearTyre : frontTyre,
                        amount: selectedAmount * 0.5,
                        tpmsAdded: tpmsAdded,
                        tpmsItem: tpmsItem,
                        axle: Axle.REAR,
                        kit: rim.rimRear ? rim.mixedDataRimInformation.kitRear : rim.mixedDataRimInformation.kitFront,
                        mountingCosts: mountedWheelDetails.mountingCostsRear ? mountedWheelDetails.mountingCostsRear : mountedWheelDetails.mountingCostsFront
                    };
                    addCompleteWheelToShoppingCart([frontReq, rearReq])
                        .then(() => {
                            if (oldPositionId) {
                                deleteOldPositionFromCart();
                            } else {
                                props.updateCartPreview();
                                hideLoader();
                                setShowAddedToCartModal(true);
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            });

    };

    const deleteOldPositionFromCart = () => {
        deletePositionFromCartById(oldPositionId).then(() => {
            props.updateCartPreview();
            hideLoader();
            setShowAddedToCartModal(true);
        }).catch(error => {
            console.error(error);
        });
    };

    const detailsClicked = () => {
        AnalyticsHelper.trackTyreClick({ ...props.tyre });
    };

    const showButton = () => {
        if (props.isCompleteWheelSearch) {
            return (
                <>
                    <button
                        className="btn w-100 btn-green mt-2 fw-normal"
                        onClick={() => {
                            detailsClicked();
                            chooseTyreForMountedWheel();
                        }}
                    >
                        <span>
                            {t("TYRES.SEARCH.ADD_TYRE")}
                        </span>
                    </button>
                </>
            );
        }
        return (
            <Link className="btn w-100 btn-green mt-2"
                  to={{
                      pathname: `/reifen/details/${props.tyre.productId}${addSeoData()}`,
                      search: new URLSearchParams(location.search).toString()
                  }}
                  onClick={() => detailsClicked()}
            >
                {t("TYRES.SEARCH.DETAILS")}
            </Link>
        );
    };

    function addSeoData() {
        if (!props.tyre) {
            return "";
        }
        return `-${StringHelper.toSnakeCase(props.tyre.brandName)}_${StringHelper.toSnakeCase(props.tyre.tread)}`;
    }

    function showTyreInfos(tyre: TyreDTO, listView: boolean = false, showRearTyre: boolean = false, priceOnly: boolean = false) {
        let amount;
        if (selectedAmount) {
            amount = props.tyre.rearTyre ? (selectedAmount * 0.5) : selectedAmount;
        } else {
            amount = 4;
        }
        let mountingCost = 0;
        if (props.mountedWheelCosts) {
            if (tyre.productId === props.tyre?.rearTyre?.productId) {
                mountingCost = props.mountedWheelCosts.mountingCostsRear.price;
                mountingCost += (props.rimDetails.rimRear?.priceAndStock?.price?.minSellInPriceNet || props.rimDetails.rimFront?.priceAndStock?.price?.minSellInPriceNet || 0);
            } else {
                mountingCost = props.mountedWheelCosts.mountingCostsFront.price;
                mountingCost += (props.rimDetails.rimFront?.priceAndStock?.price?.minSellInPriceNet || 0);
            }
        }

        if (listView) {
            return <div className={`${props.isSmallScreen ? "mt-1" : ""}`}>
                {(!props.mountedWheelCosts) && <RecommendedRetailPrice
                    price={(tyre.pricesDTO.minSellInPriceNet + mountingCost) * amount}
                    recommendedRetailPrice={tyre.pricesDTO?.recommendedRetailPrice * amount}
                    hideIfEmpty
                />
                }
                <TyrePrice price={tyre.pricesDTO.minSellInPriceNet + mountingCost}
                           isMountedWheel={!!props.mountedWheelCosts}
                           textAlign={"end"}
                           amount={amount}
                           listView={listView} priceOnly={priceOnly} />
            </div>;
        }

        return (
            <>
                <div className={"row justify-content-between px-1"}>
                    <span
                        className={`d-flex align-items-end ${props.tyre.rearTyre ? "justify-content-start" : ""}`}>
                        {tyre.rearTyre
                            ? <img src="/car_front_wheel.svg"
                                   className="axis mb-2"
                                   alt="car_front_wheel.svg"
                            />
                            : showRearTyre
                                ? <img src="/car_rear_wheel.svg"
                                       className="axis mb-2"
                                       alt="car_rear_wheel.svg"
                                />
                                : <img src="/car_front_rear_wheels.svg"
                                       className="axis mb-2"
                                       alt="car_front_rear_wheels.svg"
                                />
                        }
                    </span>

                    <span className={`tyre-info-descr ${props.tyre.rearTyre ? "col" : " "}`}>
                        <span className={` ${isVerySmallScreen && props.tyre.rearTyre ? "row" : " "}`}>
                            <span className={`${isVerySmallScreen && props.tyre.rearTyre ? "col" : " "}`}>
                                {tyre.width}/{tyre.section}R{tyre.diameter} {tyre.loadIndex}{tyre.speedIndex}
                                &nbsp;
                            </span>

                            <span>
                                {tyre.vehicleType
                                    ? t("TYRES.SEARCH.VEHICLE_TYPES." + tyre.vehicleType)
                                    : <span>&nbsp;</span>}
                                <span>&nbsp;</span>
                            </span>

                            <span className={`${isVerySmallScreen && props.tyre.rearTyre ? "col" : " "}`}>
                                {tyre.season
                                    ? t("TYRES.SEARCH.SEASONS." + tyre.season)
                                    : <span>&nbsp;</span>}
                            </span>
                        </span>
                    </span>
                </div>


                <hr />
                {tyre.tyreEULabelDTO &&
                    <div className="tyre-label mt-1 mb-4">
                        <div className="tyre-label-heading mb-2">{t("TYRES.SEARCH.EU_TYRE_LABEL")}</div>
                        <TyreEuLabel tyreEULabel={tyre.tyreEULabelDTO} />
                    </div>
                }
                {(!props.mountedWheelCosts) && <RecommendedRetailPrice
                    price={(tyre.pricesDTO.minSellInPriceNet + mountingCost) * amount}
                    recommendedRetailPrice={tyre.pricesDTO?.recommendedRetailPrice * amount} />
                }
                <TyrePrice price={tyre.pricesDTO.minSellInPriceNet + mountingCost}
                           isMountedWheel={!!props.mountedWheelCosts}
                           amount={amount} />
                <StockInfo className={"small m-0"} stock={props.tyre.availableQuantity} />
                <div className="text-end mb-2">
                    <RatePayInfo showCheckIcon={!isVerySmallScreen} className={"small"} />
                </div>
            </>
        );
    }

    return (
        <>
            {loader}
            {props.showListView
                ? <ListViewTyreCard tyre={props.tyre}
                                    showTyreInfos={showTyreInfos}
                                    showButton={showButton}
                                    noImageUrl={noImageUrl}
                                    changeFavorite={changeFavorite}
                                    isFavorite={isFavorite}
                                    smallScreen={props.isSmallScreen}
                                    isCompleteWheelSearch={props.isCompleteWheelSearch}
                                    detailsClicked={detailsClicked}
                                    chooseTyreForMountedWheel={chooseTyreForMountedWheel}
                                    isMountedWheel={!!props.mountedWheelCosts} />
                : <GridViewTyreCard tyre={props.tyre}
                                    isCompleteWheelSearch={props.isCompleteWheelSearch}
                                    showTyreInfos={showTyreInfos}
                                    showButton={showButton}
                                    noImageUrl={noImageUrl}
                                    changeFavorite={changeFavorite}
                                    isFavorite={isFavorite} />
            }
            <CustomModal open={showAddedToCartModal}>
                <AddedToCartModal close={() => setShowAddedToCartModal(false)} />
            </CustomModal>
        </>
    );
}

const mapDispatchToProps =
    {
        updateCartPreview
    }
;

export default connect(null, mapDispatchToProps)(TyreCard);
