import React from "react";
import {useTranslation} from "react-i18next";
import {paymentMethods} from "../../../Checkout/PaymentAndShipping/PaymentAndShipping";
import Tooltip from "rc-tooltip";
import {PaymentMethod} from "../../../../models/cart/CartDTO";

export default function AcceptedPaymentMethods() {
    const [t] = useTranslation();

    return (
        <div>
            <div className="heading">{t('CART.WE_ACCEPT')}</div>
            <div className="d-flex flex-wrap">
                {paymentMethods.map(paymentMethod => (
                    paymentMethod.type == PaymentMethod.PAY_PAL
                        ? <></>
                        :
                        <Tooltip key={paymentMethod.type}
                                 placement="bottom"
                                 trigger={['hover', 'click']}
                                 overlay={
                                     <div>{t('ENUMS.PAYMENT_METHODS.' + paymentMethod.type)}</div>}
                        >
                            <div className="icon-container">
                                <img className="img-fluid" src={paymentMethod.icon} alt={paymentMethod.type}/>
                            </div>
                        </Tooltip>
                ))}
            </div>
        </div>
    );
}
